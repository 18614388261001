import styled from "styled-components";

export const CalendarWrapper = styled.div`
	padding: 40px;
	> img {
		height: 12;
		width: 12;
	}
	.fc-daygrid-event-harness {
		display: flex;
		flex-wrap: wrap;  /* Allow children to wrap if there's not enough space */
		overflow: hidden;
	}
`;


